<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('MESSAGES.HEADER')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('MESSAGES.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12" vs-xs="12">
      <vs-card >

        <div id="messages_table" class="vs-con-loading__container">
        <vs-table
          search
          :data="getTableData"
          :noDataText="$t('MESSAGES.TABLE.NO_DATA')"
          stripe
          max-items="25" 
          pagination
          :multiple="selectMultiple"
          hoverFlat
          v-model="selectedMessages">

          <template slot="header">
            <h3>
              {{$t('MESSAGES.TABLE.HEADER')}}
            </h3>
          </template>
          <template slot="thead">              
            <vs-th sort-key="title">
              {{$t('MESSAGES.TABLE.COL1')}}
            </vs-th>
            <vs-th sort-key="from">
              {{$t('MESSAGES.TABLE.COL2')}}
            </vs-th>
            <vs-th sort-key="datetime">
              {{$t('MESSAGES.TABLE.COL3')}}
            </vs-th>
            <vs-th>
            </vs-th>               
            <vs-th>
            </vs-th>            
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :class="data[indextr].read == 0 ? 'message_unread':'message_read'" :key="indextr" v-for="(tr, indextr) in data"  :state="(data[indextr].color == 'success' || data[indextr].color == 'warning' || data[indextr].color == 'danger') ? data[indextr].color : null">
              <vs-td :data="data[indextr].title">
                <vs-icon v-if="data[indextr].read == 0" icon="priority_high"></vs-icon>
                {{data[indextr].title}}
              </vs-td>      
              <vs-td :data="data[indextr].from">
                {{data[indextr].from}}
              </vs-td>
              <vs-td :data="data[indextr].datetime">
                {{data[indextr].datetime}}
              </vs-td> 
              <vs-td :data="data[indextr].fileguids">
                <vs-icon v-if="data[indextr].fileguids != null ? data[indextr].fileguids.lenght>0:false" icon="attach_file"></vs-icon>
              </vs-td> 
              <vs-td>
                <div class="btn-alignment">
                  <vs-button :disabled="selectMultiple" size="small" color="primary" type="border" icon="open_in_new" @click="showMessage()"></vs-button>
                  <vs-button :disabled="selectMultiple"  size="small" color="primary" type="border" icon="delete" @click="deleteMessage(data[indextr].guid)"></vs-button>
                </div>
              </vs-td>  
            </vs-tr>
          </template>
        </vs-table>
        </div>
        <div class="d-flex mt-2">
          <vs-checkbox v-model="selectMultiple">{{$t('MESSAGES.CBX.MULTIPLE')}}</vs-checkbox>
        </div>
        <div class="d-flex mt-2">
          <vs-button :disabled="!selectMultiple" color="primary" type="border" icon="delete" @click="deleteSelectedMessages()">{{$t('COM.DELETE')}}</vs-button>
        </div>
        </vs-card>
      </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="0" vs-xs="0">
      <vs-card >
        <div slot="header">
          <h3 class="font-weight-bold">Benachrichtigung <vs-icon icon="message"></vs-icon></h3>
        </div>
        <vs-list v-if="messageSelected">
          <vs-list-item :title="this.selectedMessage.from" :subtitle="this.selectedMessage.datetime">
            <template slot="avatar">
              <vs-avatar vs-text="Vuesax"/>
            </template>
            <vs-button :disabled="!IsDocumentAvailable" type="border" icon="attach_file" @click="DownloadFile()"></vs-button>
          </vs-list-item>
          <vs-list-item class="message_title" :title="this.selectedMessage.title" :subtitle="this.selectedMessage.text"></vs-list-item>
        </vs-list>
        <div v-if="!messageSelected"><vs-icon class="mr-1" icon="info"></vs-icon>{{$t('MESSAGES.MESSAGE.NO_SELECTED')}}</div>
      </vs-card>
    </vs-col>      
  </vs-row>
  <MessagePopUp ref="myMessagePopUp" :Guid="this.selectedMessage.guid" :Title="this.selectedMessage.title" :Text="this.selectedMessage.text" :DateTime="this.selectedMessage.datetime" :From="this.selectedMessage.from" :DocumentIds="this.selectedMessage.fileIds"/>
</div>
</template>

<script>

import MessagePopUp from './components/messages/MessagePopUp';
import codeHelper from '../helper/staticCodeHelper';
import downloadMethods from '../helper/staticDownloadFuncHelper';

export default {
  name: "Messages",
  components: {
    MessagePopUp
  },
  data: () => ({
      messages:[],
      selectedMessages:[],
      selectedMessage:{title:"",from:"",text:"",fileguids:"",datetime:""},
      selectMultiple:false
  }),
  created () {
        this.LoadData();
    },
  computed: 
  {
    messageSelected(){
      var retVal = false;
      if(typeof(this.selectedMessage.from) != 'undefined')
      {
        retVal= this.selectedMessage.from.length > 0;
      }
      return retVal;
    },
    getTableData() {
      var data = [];
      if(this.$store.state.messages.messages.data != null)
      {
        data = this.$store.state.messages.messages.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.messages.messages.status) != 'undefined')
        loading = this.$store.state.messages.messages.status.loading;
      return loading;
    },
    IsDocumentAvailable(){
      var retVal = false;
      if(this.IsNotNull(this.selectedMessage.fileIds))
      {
          retVal = this.selectedMessage.fileIds.length > 0;
      }
      return retVal;
    }
  },
  watch: {
    selectMultiple(){

        this.selectedMessages = [];
      
    },
    selectedMessages(value){

      if(this.selectMultiple == false)
      {
        if(typeof(value.from) != 'undefined')
        {
          if(value.from.length > 0)
          {
            var tmp = JSON.stringify(value);
            this.selectedMessage = JSON.parse(tmp);
            this.$store.dispatch('messages/markAsRead', this.selectedMessage.guid ); 
          }
        }
      }
      else
      {
        this.clearSelectedMessage();
      }
    },
      getTableData(value) {
          this.$data.messages = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#messages_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#messages_table'});
        }
      }
    },
  methods: {
    ...codeHelper,
    ...downloadMethods,
    deleteSelectedMessages(){
      this.selectedMessages.forEach(message=>{
        this.$store.dispatch('messages/markAsDeleted', message.guid ); 
      });
    },
    clearSelectedMessage()
    {
        this.selectedMessage = {title:"",from:"",text:"",fileguids:"",datetime:""};
    },
    deleteMessage(guid){
      this.clearSelectedMessage();
      this.$store.dispatch('messages/markAsDeleted', guid ); 
    },
      showMessage(){
        this.$refs.myMessagePopUp.ShowPopUp();
      },
      LoadData: function ()
      {
          // Nachrichten abfragen
          if(this.$store.state.messages.lastUpdate == null)
          {
            // Alle abfragen
            this.$store.dispatch('messages/getMessages', { root: true },{ root: true }); 
          }
          else
          {
            // Nur die ungelesenen seit der letzten Abfrage
            this.$store.dispatch('messages/getUnreadMessages', this.$store.state.messages.lastUpdate ); 
          }
      },
      DownloadFile(){
          if(this.IsDocumentAvailable)
          {
              var ids = this.selectedMessage.fileIds.split("#");

              for(var i=0; i < ids.length; i++)
              {
                  this.$store.dispatch('document/downloadDocument', Number(ids[i]))
                  .then(response => {
                      this.DownloadWithFileSaver(response);
                  });
              }
              
          }
        }  

  }
};

</script>
<style scoped>
  .message_unread{
    font-weight: bold;
  }
</style>